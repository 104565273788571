<template>
  <div class="banner" :class="{ newyear: theme === 'newyear' }">
    <div class="title">Приветственный<br />бонус</div>
    <div class="desc">Поспеши получить<br />свой бонус !</div>
    <router-link to="/profile/bonuses/promo" :class="{ disabled: !isLoggedIn }">
      {{ isLoggedIn ? "Получить сейчас" : "Необходимо авторизоваться" }}
    </router-link>
    <img alt="Trophy" src="@/assets/images/banners/trophy-welcome-bonus.png" />
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
export default {
  computed: {
    ...mapGetters("user", ["isLoggedIn"]),
    ...mapState(["theme"]),
  },
};
</script>

<style lang="scss" scoped>
.banner {
  border-radius: 6px;
  background-image: url("~@/assets/images/banners/welcome-bonus-bg.png");
  background-size: cover;
  padding: 35px 45px;
  position: relative;
  &.newyear::after {
    content: "";
    position: absolute;
    left: 0;
    top: -15px;
    right: 0;
    width: 100%;
    height: 68px;
    background-image: url("~@/assets/icons/snow-banner.png");
    object-fit: contain;
  }
  .title {
    font-weight: bold;
    font-style: italic;
    font-size: 50px;
    color: white;
    text-transform: uppercase;
  }
  .desc {
    position: absolute;
    left: 240px;
    top: 105px;
    color: #d1d5df;
    font-size: 16px;
  }
  img {
    position: absolute;
    right: 0;
    top: -10px;
    height: 280px;
    object-fit: contain;
  }
  a {
    background: #ffffff;
    border-radius: 8px;
    padding: 15px 30px;
    display: inline-block;
    color: black;
    font-size: 15px;
    font-weight: 500;
    margin-top: 15px;

    transition: 200ms ease;
    &.disabled {
      opacity: 0.8;
      pointer-events: none;
    }
    &:hover {
      opacity: 0.8;
      cursor: pointer;
    }
  }
}
</style>
