<template>
  <div class="games">
    <router-link to="/dice" class="dice">
      <div class="users">
        <img alt="user-white" src="@/assets/icons/user-white.svg" />
        {{ data.dice_classic }}
      </div>
      <div class="game-title">Dice</div>
      <div class="hover">
        <img alt="Играть" src="@/assets/images/games/play-polygon.png" />
        <span class="title">Играть</span>
      </div>
    </router-link>
    <router-link to="/x100" class="x100">
      <div class="users">
        <img alt="user-white" src="@/assets/icons/user-white.svg" />
        {{ data.x100 }}
      </div>
      <div class="game-title">X100</div>
      <div class="hover">
        <img alt="Играть" src="@/assets/images/games/play-polygon.png" />
        <span class="title">Играть</span>
      </div>
    </router-link>
    <router-link to="/dice/bonus" class="bonusdice disabled">
      <div class="users">
        <img alt="user-white" src="@/assets/icons/user-white.svg" />
        0
      </div>
      <div class="game-title">Скоро</div>
      <div class="hover">
        <img alt="Играть" src="@/assets/images/games/play-polygon.png" />
        <span class="title">Играть</span>
      </div>
    </router-link>
    <router-link to="/megawheel" class="megawheel disabled">
      <div class="users">
        <img alt="user-white" src="@/assets/icons/user-white.svg" />
        0
      </div>
      <div class="game-title">Скоро</div>
      <div class="hover">
        <img alt="Играть" src="@/assets/images/games/play-polygon.png" />
        <span class="title">Играть</span>
      </div>
    </router-link>
    <router-link to="/crazytime" class="crazytime disabled">
      <div class="users">
        <img alt="user-white" src="@/assets/icons/user-white.svg" />
        0
      </div>
      <div class="game-title">Скоро</div>
      <div class="hover">
        <img alt="Играть" src="@/assets/images/games/play-polygon.png" />
        <span class="title">Играть</span>
      </div>
    </router-link>
  </div>
</template>

<script>
export default {
  data() {
    return {
      broadcast: null,
      data: {
        dice_classic: 0,
        x100: 0,
      },
      cdn: this.CDN_URL,
    };
  },

  destroyed() {
    if (this.broadcast !== null) this.broadcast.unsubscribe();
  },

  mounted() {
    this.broadcast = this.$centrifuge.subscribe("live:online", ({ data }) =>
      this.handle(data)
    );

    this.broadcast.history().then(({ publications }) => {
      let array = publications.reverse();
      this.handle(array[0].data);
    });
  },

  methods: {
    handle(data) {
      this.data = data;
    },
  },
};
</script>

<style lang="scss" scoped>
.games {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -7.5px;
  a {
    margin: 0 7.5px 15px 7.5px;
    display: block;
    position: relative;
    overflow: hidden;
    width: calc(100% / 5 - 15px);
    height: calc(548.14px / 2.125);

    @media (max-width: 768px) {
      width: calc(100% / 2 - 15px);
      height: 35vh;
      background-position: center;
    }
    padding: 20px;
    div.users {
      position: relative;
      z-index: 1;
      color: white;
      font-weight: 500;
      text-shadow: 0 0 9px rgb(0 0 0 / 48%);
      font-size: 15px;
      img {
        margin-right: 10px;
      }
    }
    border-radius: 6px;
    background-repeat: no-repeat;
    background-size: cover;
    &.mines {
      background-image: url("~@/assets/images/games/mines.png");
    }
    &.dice {
      background-image: url("~@/assets/images/games/dice.png");
    }
    &.bubbles {
      background-image: url("~@/assets/images/games/bubbles.png");
    }
    &.bonusdice {
      background-image: url("~@/assets/images/games/bonusdice.png");
    }
    &.x100 {
      background-image: url("~@/assets/images/games/x100.png");
    }
    &.megawheel {
      background-image: url("~@/assets/images/games/megawheel.png");
    }
    &.crazytime {
      background-image: url("~@/assets/images/games/crazytime.png");
    }
    &.disabled {
      pointer-events: none;
      opacity: 0.4;
    }
    .hover {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      opacity: 0;
      background-color: rgba(black, 0.5);
      text-align: center;
      transition: 200ms ease;
      img {
        display: block;
        margin: 80px auto 0 auto;
        padding: 15px;
        border-radius: 10px;
        background-color: rgba(white, 0.15);
      }
      span {
        display: block;
        color: white;
        font-weight: 500;
        margin-top: 15px;
        font-size: 17px;
        text-transform: uppercase;
      }
    }
    .game-title {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 25px;
      text-transform: uppercase;
      color: white;
      text-align: center;
      font-size: 25px;
      font-weight: 600;
      text-shadow: 3px 2px rgba(black, 0.2);
    }
    &:hover .hover {
      opacity: 1;
    }
  }
}
</style>
