<template>
  <div class="home">
    <div class="banners">
      <WelcomeBonus class="welcome_bonus" />
      <FreeWheel class="free_wheel" />
    </div>
    <Games />
    <History />
  </div>
</template>

<script>
import WelcomeBonus from "@/components/Banner/WelcomeBonus";
import FreeWheel from "@/components/Banner/FreeWheel";
import Games from "@/components/Card/Games";
import History from "@/components/Table/History";

export default {
  components: {
    WelcomeBonus,
    FreeWheel,
    Games,
    History,
  },
};
</script>

<style lang="scss" scoped>
.banners {
  display: flex;
  margin-bottom: 15px;
  .welcome_bonus {
    width: calc(100% - 400px - 15px);
    height: 249px;
    margin-right: 15px;
  }
  .free_wheel {
    width: 400px;
    height: 249px;
  }

  @media (max-width: 768px) {
    display: none;
  }
}
</style>
